import React from 'react'
import '../styles/index.css'
import { useIntl } from 'gatsby-plugin-intl'
import { Link } from 'gatsby'

const EmployeeCard = props => {
  const intl = useIntl()
  const locale = intl.locale !== 'sv' ? `/${intl.locale}` : ''
  return (
    <div className="what-we-do-card">
      <span
        style={{
          fontFamily: 'ElegantIcons',
          fontSize: '25px',
          backgroundColor: 'rgba(46, 74, 101,.1)',
          width: '55px',
          height: '55px',
          padding: '15px',
          borderRadius: '10px',
          boxSizing: 'border-box',
          color: '#2E4A65',
          marginTop: '25px',
        }}
      >
        {props.icon}
      </span>
      <h4 className="card-title" style={{ fontSize: '16px' }}>
        {props.specialize}
      </h4>
      <p className="card-content">{props.content}</p>
      <Link className="read-more-btn-a" to={`${locale}${props.path}`}>
        <button className="read-more-btn">{props.specialize}</button>
      </Link>
    </div>
  )
}

export default EmployeeCard
