import React, { useState } from 'react'
import '../styles/header.css'
import { Link } from 'gatsby'
import { useStaticQuery, graphql } from 'gatsby'
import { List } from 'react-bootstrap-icons'
import SvFlag from '../images/sv-flag.png'
import EnFlag from '../images/en-flag.png'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import CloseIcon from '@material-ui/icons/Close'
import { useIntl } from 'gatsby-plugin-intl'
import { useTranslation } from 'react-i18next'
import logo from '../images/logo-large-white.png'

const Header = () => {
  const { t } = useTranslation()

  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(filter: { frontmatter: { key: { eq: "menu" } } }) {
        edges {
          node {
            id
            frontmatter {
              menuObject {
                about {
                  name
                  path
                }
                contact {
                  name
                  path
                }
                partners {
                  name
                  path
                }
                start {
                  name
                  path
                }
                whatwedo {
                  name
                  path
                }
                recruite {
                  name
                  path
                }
              }
              dropdownMenu {
                name
                path
              }
            }
          }
        }
      }
    }
  `)

  const [isOpen, setIsOpen] = useState(false)
  const [isDropDownOpen, setDropDownOpen] = useState(false)
  const intl = useIntl()
  const locale = intl.locale !== 'sv' ? `/${intl.locale}` : ''

  let md = data.allMarkdownRemark.edges[0].node.frontmatter.menuObject
  let dropdownArray =
    data.allMarkdownRemark.edges[0].node.frontmatter.dropdownMenu
  let DropdownMenu = dropdownArray.map((item, i) => (
    <li key={i} className="dropdown-li">
      <Link className="dropdown-a" to={`${locale}${item.path}`}>
        {t(`dropDownName.${i}`)}
      </Link>
    </li>
  ))

  function mobileMenu() {
    if (isOpen === false) {
      setIsOpen(!isOpen)
    } else {
      setIsOpen(!isOpen)
    }
  }

  function dropDown() {
    if (dropDown === false) {
      setDropDownOpen(!isDropDownOpen)
    } else {
      setDropDownOpen(!isDropDownOpen)
    }
  }

  return (
    <div id="header">
      <header className="banner-area">
        <Link to={`${locale}/`}>
          <img
            className="cloudenablers-icon"
            src={logo}
            alt="CloudEnablers AB"
          />
        </Link>
        <label onClick={mobileMenu} htmlFor="nav" className="nav-btn">
          <span>
            {isOpen ? (
              <CloseIcon
                style={{
                  fontSize: '40px',
                  color: '#000',
                  position: 'fixed',
                  right: '45px',
                }}
              />
            ) : (
              <List style={{ position: 'absolute', right: '22px' }} />
            )}
          </span>
        </label>
        <input type="checkbox" name="" id="nav"></input>
        <div className="nav-overlay">
          <ul className="standard-ul">
            <li className="standard-li">
              <Link to={`${locale}/`}>
                {intl.formatMessage({ id: 'header.index' })}
              </Link>
            </li>
            <li className="standard-li">
              <span className="dropdown">
                <a href="#" className="dropbtn" onClick={dropDown}>
                  {intl.formatMessage({ id: 'header.whatwedo' })}
                  <KeyboardArrowDownIcon className="arrow-down" href="/" />
                </a>
                {isDropDownOpen ? (
                  <div className="dropdown-content">
                    <ul className="dropdown-ul">{DropdownMenu}</ul>
                  </div>
                ) : (
                  <div></div>
                )}
              </span>
            </li>
            <li className="standard-li">
              <Link to={`${locale}${md.partners.path}`}>
                {intl.formatMessage({ id: 'header.partners' })}
              </Link>
            </li>
            <li className="standard-li">
              <Link to={`${locale}${md.about.path}`}>
                {intl.formatMessage({ id: 'header.about' })}
              </Link>
            </li>
            <li className="standard-li">
              <Link to={`${locale}${md.contact.path}`}>
                {intl.formatMessage({ id: 'header.contact' })}
              </Link>
            </li>
            <li className="standard-li">
              <Link to={`${locale}${md.recruite.path}`}>
                {intl.formatMessage({ id: 'header.recruite' })}
              </Link>
            </li>

            {locale ? (
              <Link className="header-lang" to="/" hrefLang="sv">
                <img
                  style={{ marginBottom: '-6px' }}
                  className="flags"
                  src={SvFlag}
                  alt="sv"
                />
                Svenska
              </Link>
            ) : (
              <Link className="header-lang" to="/en" hrefLang="en">
                <img
                  style={{ marginBottom: '-6px' }}
                  className="flags"
                  src={EnFlag}
                  alt="en"
                />
                English
              </Link>
            )}
          </ul>
        </div>
      </header>
    </div>
  )
}

export default Header
