import React from 'react'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { useIntl } from 'gatsby-plugin-intl'
import 'material-icons/iconfont/material-icons.css'

import Header from '../components/header'
import Footer from '../components/footer'

import '../styles/recruite.css'

import { Heart } from 'react-bootstrap-icons'
import { People } from 'react-bootstrap-icons'
import { PersonCheck } from 'react-bootstrap-icons'

const Recruite = ({ data }) => {
  const intl = useIntl()

  return (
    <div id="recruite">
      <BackgroundImage
        className="header-img"
        fluid={data.recruiteImage.childImageSharp.fluid}
        alt="Recruite header image"
      >
        <Header />
        <div className="selling-text">
          <h1>{intl.formatMessage({ id: 'recruite.title' })}</h1>
        </div>
      </BackgroundImage>
      <main>
        <div className="grey-background-grid">
          <div className="working-section">
            <div className="work-with-us">
              <p className="blue-text">
                {intl.formatMessage({ id: 'about.culture' })}
              </p>
              <h2 className="section-title">
                {intl.formatMessage({ id: 'about.workWithUs' })}
              </h2>
              <p className="section-text">
                {intl.formatMessage({ id: 'about.workWithUsP' })}
              </p>
            </div>
          </div>
          <div className="information">
            <div className="information-block">
              <span>
                <PersonCheck className="icons" />
              </span>
              <h4 className="section-title h4">
                {intl.formatMessage({ id: 'about.responsibility' })}
              </h4>
              <p className="section-text-small">
                {intl.formatMessage({ id: 'about.responsibilityText' })}
              </p>
            </div>
            <div className="information-block">
              <span>
                <People className="icons" />
              </span>
              <h4 className="section-title h4">
                {intl.formatMessage({ id: 'about.learn' })}
              </h4>
              <p className="section-text-small">
                {intl.formatMessage({ id: 'about.learnText' })}
              </p>
            </div>
            <div className="information-block">
              <span>
                <Heart className="icons" />
              </span>
              <h4 className="section-title h4">
                {intl.formatMessage({ id: 'about.health' })}
              </h4>
              <p className="section-text-small">
                {intl.formatMessage({ id: 'about.healthText' })}
              </p>
            </div>
          </div>
        </div>
        <div className="open-roles">
          <h2 className="work-roles section-title">
            {intl.formatMessage({ id: 'recruite.workRoles' })}
          </h2>

          <p className="find-role-p">
            <strong>
              Hittar du inte en roll som passar? Du är alltid välkommen att
              skicka en spontanansökan till rekrytering@cloudenablers.se, så
              kontaktar vi dig.
            </strong>
          </p>
        </div>
      </main>
      <Footer />
    </div>
  )
}

export const query = graphql`
  query recruiteQuery {
    allMarkdownRemark(filter: { frontmatter: { key: { eq: "recruite" } } }) {
      edges {
        node {
          frontmatter {
            title
            aboutUs
            cloudBasedCompany
            cloudBasedCompanyH3
            cloudBasedCompanyP
            ourHistory
            innovation
            p1
            p2
            p3
            p4
            coWorkers
            team
            culture
            workWithUs
            workWithUsP
            responsibility
            responsibilityText
            learn
            learnText
            health
            healthText
            employeeArray {
              name
              role
              image {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
    recruiteImage: file(relativePath: { eq: "recruite-image.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    developerImage: file(relativePath: { eq: "developer-image-recruite.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    projectleaderImage: file(relativePath: { eq: "project-leader.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    analyticsImage: file(relativePath: { eq: "cloud-analytics.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default Recruite
