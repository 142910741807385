import React from 'react'
import '../styles/dynamic-pages-css/digital-innovation.css'

const DigitalInnovationContent = props => {
  return (
    <div>
      <div className="container-desktop">
        <div className="information-block">
          <div className="step">
            <span
              style={{
                fontFamily: 'ElegantIcons',
                fontSize: '25px',
                backgroundColor: 'rgba(46, 74, 101,.1)',
                width: '55px',
                height: '55px',
                padding: '15px',
                borderRadius: '10px',
                boxSizing: 'border-box',
                color: '#2E4A65',
                marginTop: '25px',
              }}
            >
              {props.icon}
            </span>
            <h4 className="title blue">{props.blockStep}</h4>
          </div>
          <h4 className="title black">{props.blockTitle}</h4>
          <p className="text">{props.blockText}</p>
        </div>
      </div>
      <div className="container-mobile">
        <div className="information-block-mobile">
          <span
            style={{
              fontFamily: 'ElegantIcons',
              fontSize: '25px',
              backgroundColor: 'rgba(46, 74, 101,.1)',
              width: '55px',
              height: '55px',
              padding: '15px',
              borderRadius: '10px',
              boxSizing: 'border-box',
              color: '#2E4A65',
              marginTop: '25px',
            }}
          >
            {props.icon}
          </span>
          <h4 className="title-mobile blue-mobile">{props.blockStep}</h4>
          <h4 className="title-mobile black-mobile">{props.blockTitle}</h4>
        </div>
        <p className="text-mobile">{props.blockText}</p>
      </div>
    </div>
  )
}

export default DigitalInnovationContent
