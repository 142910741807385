import React from 'react'
import '../styles/contact.css'

const ContactInfo = props => {
  return (
    <div className="contact-info-section">
      <span
        style={{
          fontFamily: 'ElegantIcons',
          fontSize: '25px',
          backgroundColor: 'rgba(46, 74, 101,.1)',
          width: '55px',
          height: '55px',
          padding: '15px',
          borderRadius: '10px',
          boxSizing: 'border-box',
          color: '#2E4A65',
          marginTop: '25px',
        }}
      >
        {props.icon}
      </span>
      <h4 className="title-header">{props.type}</h4>
      <p className="info-text">{props.text}</p>
      <p className="number">{props.number}</p>
      <p className="mail">{props.mail}</p>
    </div>
  )
}

export default ContactInfo
