import React from 'react'
import { withTrans } from '../i18n/withTrans'
import { Helmet } from 'react-helmet'
import CookieConsent from 'react-cookie-consent'

function Layout({ children }) {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Cloud Enablers - Molntjänster - Cloud Services</title>
        <html lang="sv" />
        <meta
          name="description"
          content="Molntjänst experter - vi kan allt inom AWS, New Relic och Microsoft Azure."
        />
      </Helmet>
      {children}
      <CookieConsent
        enableDeclineButton
        location="bottom"
        buttonText="Acceptera"
        declineButtonText="Neka"
        cookieName="gatsby-gdpr-google-analytics"
        style={{
          background: '#E8F1FF',
          /*           border: '1px solid lightgrey', */
          color: 'black',
          /*           borderRadius: '20px', */
          textAlign: 'center',
          display: 'grid',
          justifyContent: 'center',
          padding: '1em',
        }}
        buttonStyle={{
          backgroundColor: '#2E4A65',
          width: '8em',
          fontWeight: 'bold',
          color: '#FFFFFF',
          fontSize: '15px',
          borderRadius: '30px',
          padding: '15px',
        }}
        declineButtonStyle={{
          backgroundColor: '#578BBC',
          color: '#FFFFFF',
          fontSize: '15px',
          width: '8em',
          fontWeight: 'bold',
          borderRadius: '30px',
          padding: '15px',
        }}
      >
        <strong>
          Webbplatsen använder cookies för att säkerställa att du får den bästa
          upplevelsen.
        </strong>
      </CookieConsent>
    </div>
  )
}
export default withTrans(Layout)
