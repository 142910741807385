import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import { useIntl } from 'gatsby-plugin-intl'

//COMPONENTS
import Header from '../components/header'
import Footer from '../components/footer'
import EmployeeCard from '../components/employeeCard'

//STYLES
import '../styles/about.css'

const About = ({ data }) => {
  const intl = useIntl()

  let employeeArray =
    data.allMarkdownRemark.edges[0].node.frontmatter.employeeArray //Array of employees.

  let employeeCards = employeeArray.map((employee, i) => (
    <EmployeeCard
      key={i}
      image={employee.image.childImageSharp.fluid}
      name={employee.name}
      role={employee.role}
    />
  )) //Display all the employees on line 71. This is sent down to employeeCard component as props.

  return (
    <div id="about">
      <BackgroundImage
        className="header-img"
        fluid={data.aboutImage.childImageSharp.fluid}
        alt="About header image"
      >
        <Header />
        <div className="selling-text">
          <h1>{intl.formatMessage({ id: 'about.title' })}</h1>
        </div>
      </BackgroundImage>
      <main>
        <div className="first-grid">
          <div className="about-us-grid">
            <p className="blue-text">
              {intl.formatMessage({ id: 'about.aboutUs' })}
            </p>
            <h2 className="section-title">
              {intl.formatMessage({ id: 'about.cloudBasedCompany' })}
            </h2>
            <h3 className="ingress">
              {intl.formatMessage({ id: 'about.cloudBasedCompanyH3' })}
            </h3>
            <p className="section-text">
              {intl.formatMessage({ id: 'about.cloudBasedCompanyText' })}
            </p>
          </div>
          <div>
            <Img
              className="working-image gatsby-image"
              fluid={data.proxyImage.childImageSharp.fluid}
              alt="workingspace"
            />
            <Img
              className="office-image gatsby-image"
              fluid={data.officeImage.childImageSharp.fluid}
              alt="workingspace"
            />
          </div>
        </div>

        <div className="grey-background-grid">
          <div className="history-section">
            <div className="history-grid-image">
              <Img
                className="history-image gatsby-image"
                fluid={data.workingImage.childImageSharp.fluid}
                alt="workingspace"
              />
            </div>
            <div className="history-grid-text">
              <p className="blue-text">
                {intl.formatMessage({ id: 'about.ourHistory' })}
              </p>
              <h2 className="section-title">
                {intl.formatMessage({ id: 'about.innovation' })}
              </h2>
              <p className="ingress">
                {intl.formatMessage({ id: 'about.p1' })}
              </p>
              <p className="section-text-small">
                {intl.formatMessage({ id: 'about.p2' })}
              </p>
              <p className="section-text-small">
                {intl.formatMessage({ id: 'about.p3' })}
              </p>
              <p className="section-text-small">
                {intl.formatMessage({ id: 'about.p4' })}
              </p>
            </div>
          </div>
        </div>
        <div className="coWorkers">
          <p className="blue-text">
            {intl.formatMessage({ id: 'about.coWorkers' })}
          </p>
          <h2 className="section-title">
            {intl.formatMessage({ id: 'about.team' })}
          </h2>
          <div className="card-section">{employeeCards}</div>
        </div>
      </main>
      <Footer />
    </div>
  )
}

export const query = graphql`
  query aboutQuery {
    allMarkdownRemark(filter: { frontmatter: { key: { eq: "about" } } }) {
      edges {
        node {
          frontmatter {
            title
            aboutUs
            cloudBasedCompany
            cloudBasedCompanyH3
            cloudBasedCompanyP
            ourHistory
            innovation
            p1
            p2
            p3
            p4
            coWorkers
            team
            culture
            workWithUs
            workWithUsP
            responsibility
            responsibilityText
            learn
            learnText
            health
            healthText
            employeeArray {
              name
              role
              image {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
    workingImage: file(relativePath: { eq: "DSC00522.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    proxyImage: file(relativePath: { eq: "DSC00573.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    officeImage: file(relativePath: { eq: "DSC00681.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    aboutImage: file(relativePath: { eq: "DSC00499.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default About
